<template>
  <div class="atom-content-slider slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomContentSlider",
  props: {}
}
</script>

<style lang="scss" scoped>
  .atom-content-slider {
    p {
      font-weight: $regular;
      font-size: 1rem;
      line-height: 1.563rem;
      color: $white;
      margin-bottom: 1.875rem;
      text-align: justify;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 29px;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .verticale-line-white {
      display: block;
      height: 1px;
      background: $white;
      margin: 0.625rem 0 2.5rem;
      @media only screen and (max-width: 767px) {
        margin: 10px 0 20px;
      }
    }
  }
</style>