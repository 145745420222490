<template>
  <div class="col col-xs-12 col-4 atom-padding">
    <div class="atom-creator">
      <div class="shadow-element"></div>
      <div class="atom-gradient-background">
        <AtomLazyLoader :height="425" v-if="!isInView" />
        <picture v-lazyload="{handle: handleLazyLoad, url: assetUrl}">
          <source :srcset="assetUrl">
          <img v-if="assetUrl" :src="assetUrl" :alt="name">
        </picture>
        <span class="name">{{ name }}</span>
        <a target="_blank" class="linkedin-icon" :href="`${urlLinkedin}`">
          <AtomIcon icon="linkedin" color="#fff" :size="35" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../common/AtomIcon";
import config from "../../../config";
import AtomLazyLoader from "../common/AtomLazyLoader";
export default {
  name: "AtomCreator",
  components: {AtomLazyLoader, AtomIcon},
  props: {
    image: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String,
      default: ''
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  },
  data() {
    return {
      isInView: false,
    }
  },
  computed: {
    client() {
      return config.CLIENT
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    assetUrl() {
      if (!this.image || !this.isInView) return '';
      if (this.image.includes('://')) {
        return this.image
      }
      return `${this.cdnBase}/${this.image}`
    }
  },
  methods: {
    handleTracking() {
      if (config.CLIENT === 'one' && config.RUNTIME_ENV === 'production' && 'lintrk' in window) {
        window.lintrk('track', { conversion_id: 4403868 });
      }
    },
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-padding {
    margin-bottom: 5.25rem;
    padding: 0 2.625rem;
    @media only screen and (max-width: 767px) {
      padding: 0 30px;
      margin-bottom: 30px;
    }
    .atom-creator {
      position: relative;
    }
    .shadow-element {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $black;
    }
    .atom-gradient-background {
      position: relative;
      z-index: 5;
      padding: 1px;
      background: linear-gradient(180deg, rgba(255,255,255,0.8911939775910365) 0%, rgba(255,255,255,0.5634628851540616) 50%, rgba(0,0,0,0) 100%);
      picture {
        display: block;
        width: 100%;
        max-width: 100%;
        img {
          display: block;
          width: 100%;
          max-width: 100%;
        }
      }
      .name {
        color: $white;
        font-weight: $medium;
        font-size: 1.5rem;
        line-height: 1.813rem;
        text-transform: uppercase;
        max-width: 13.75rem;
        display: block;
        position: absolute;
        left: 1.875rem;
        bottom: 2.5rem;
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 23px;
          max-width: 140px;
          left: 15px;
          bottom: 15px;
        }
      }
      .linkedin-icon {
        position: absolute;
        right: 1.875rem;
        bottom: 2.5rem;
        width: 4.188rem;
        height: 4.188rem;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(4px);
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px) {
          right: 15px;
          bottom: 15px;
          width: 46px;
          height: 46px;
        }
        .icon {
          font-size: 2.188rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 25px !important;
          }
        }
      }
    }
    &:nth-of-type(1) {
      .shadow-element {
        right: -2rem;
        bottom: -2rem;
        @media only screen and (max-width: 767px) {
          right: 0;
          bottom: -15px;
        }
      }
    }
    &:nth-of-type(2) {
      .shadow-element {
        bottom: -2rem;
        @media only screen and (max-width: 767px) {
          bottom: -15px;
        }
      }
    }
    &:nth-of-type(3) {
      .shadow-element {
        left: -2rem;
        bottom: -2rem;
        @media only screen and (max-width: 767px) {
          left: 0;
          bottom: -15px;
        }
      }
    }
  }
</style>