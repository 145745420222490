var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-title-and-description" }, [
    _c("h4", [_vm._v(_vm._s(_vm.title))]),
    _c("p", [_vm._v(_vm._s(_vm.description))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }