<template>
  <div class="atom-project-portfolio-padding">
    <div v-lazyload="{handle: handleLazyLoad, url: resourceUrl}" :id="`${projectId}`" :class="{'atom-project-portfolio': true, 'active': isActive}" :style="{'background-image': `url(${resourceUrl})`}" @click="handleClick">
      <div class="atom-project-portfolio-gradient">
        <h3>{{ title }} <br/>{{ subtitle }}</h3>
        <AtomVerticalLine color="#fff" />
        <AtomContentText color="#fff" :text="`${ text }`" />
        <AtomButtonLink label="Learn more" icon="right" :href="`${ href }`" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomVerticalLine from "../common/AtomVerticalLine";
import AtomButtonLink from "../common/AtomButtonLink";
import AtomContentText from "../common/AtomContentText";
export default {
  name: "AtomProjectPortfolio",
  components: {AtomContentText, AtomButtonLink, AtomVerticalLine},
  props: {
    image: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String,
      default: ''
    },
    subtitle: {
      required: true,
      type: String,
      default: ''
    },
    text: {
      required: true,
      type: String,
      default: ''
    },
    href: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    tabId: {
      required: true,
      type: String,
      default: ''
    },
    projectId: {
      required: true,
      type: String,
      default: ''
    },
    activeProject: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  data() {
    return {
      isInView: false,
    }
  },
  mounted() {
    // this.hasMounted = true;
  },
  computed: {
    isActive() {
      return this.$props.activeProject === this.tabId;
    },
    resourceUrl() {
      if (!this.isInView) return '';
      return `${this.$store.getters.cdnBase}/${this.$props.image}`;
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', {
        tabId: this.tabId,
        projectId: this.projectId,
      });
    },
    handleLazyLoad() {
      this.isInView = true;
    }
  }
}
</script>

<style lang="scss">
  .atom-project-portfolio-padding {
    flex: 1;
    padding-bottom: 0.25rem;
    @media only screen and (max-width: 767px) {
      padding-bottom: 0;
      display: inline-block;
      vertical-align: bottom;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .atom-project-portfolio {
      position: relative;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition: all 0.5s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
      .atom-project-portfolio-gradient {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 1.25rem 0 5.625rem;
        cursor: pointer;
        background: linear-gradient(0deg, $blackOpacity08 0%, $blackOpacity00 100%);
        transition: all 0.3s ease-in-out 0s;
        @media only screen and (max-width: 767px) {
          padding: 10px;
          justify-content: flex-end;
        }
        .atom-vertical-line {
          opacity: 0;
          overflow: hidden;
          max-height: 0;
          transition: all 0.5s ease-in-out 0s;
        }
        h3 {
          font-weight: $medium;
          font-size: 1.5rem;
          line-height: 1.813rem;
          text-transform: uppercase;
          color: $white;
          margin-bottom: 0.938rem;
          transition: all 0.5s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            white-space: break-spaces;
            font-size: 14px;
            line-height: 17px;
            margin: 0;
          }
        }
        .link-atom {
          opacity: 0;
          overflow: hidden;
          max-height: 0;
          width: 12.5rem;
          padding: 0;
          transition: all 0.5s ease-in-out 0s;
          @media only screen and (max-width: 767px) {
            width: 115px;
            max-height: 500px;
            padding: 5px 10px;
            margin: 10px 0 0;
            display: none;
            span {
              font-size: 10px !important;
              line-height: 15px !important;
            }
            .icon {
              font-size: 10px !important;
              margin-left: 5px !important;
            }
          }
        }
      }
      &:hover, &.active {
        .atom-project-portfolio-gradient {
          background: rgba(0,0,0,0.8);
        }
      }
      &.active {
        @media only screen and (max-width: 767px) {
          width: 235px;
          height: 120px;
        }
        .atom-project-portfolio-gradient {
          padding: 2.813rem 1.25rem 2.813rem 5.625rem;
          @media only screen and (max-width: 767px) {
            background: transparent;
            padding: 10px;
          }
        }
        .atom-vertical-line {
          opacity: 1;
          max-height: 2px;
          margin-bottom: 0.938rem;
        }
        .link-atom{
          opacity: 1;
          padding: 0.75rem 0.625rem;
          max-height: 3.125rem;
          margin-top: 1.25rem;
          @media only screen and (max-width: 767px) {
            max-height: 500px;
            padding: 5px 10px;
            margin: 10px 0 0;
            display: block;
          }
        }
      }
    }
  }
</style>