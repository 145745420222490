<template>
  <div :class="{'atom-scroll-up': true, 'active': isScrolled}" @click="goBackToTopSection">
    <AtomIcon icon="angle-up" :size="30" color="#000"></AtomIcon>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";
import {convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId} from "../../../helpers/util";
export default {
  name: "AtomScrollUp",
  components: {AtomIcon},
  data() {
    return {
      isScrolled: false,
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 1000;
    },
    goBackToTopSection() {
      const sections = this.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[0];
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type);
        smoothScrollToTargetId(`#${targetString}`);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-scroll-up {
    display: none;
    position: fixed;
    right: 0.625rem;
    bottom: 0.625rem;
    width: 2.5rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    background: #E1DDF4;
    cursor: pointer;
    z-index: 9;
    @media only screen and (max-width: 767px) {
      right: 10px;
      bottom: 10px;
      width: 20px;
      height: 30px;
      line-height: 30px;
    }
    .icon {
      font-size: 1.875rem !important;
      @media only screen and (max-width: 767px) {
        font-size: 15px !important;
      }
    }
    &.active {
      display: block;
    }
  }
</style>