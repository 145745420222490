<template>
  <div :class="{section: true, 'section-vision': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle color="#fff" color2="#fff" :title="titleString" :subtitle="subtitleString" />
    </div>
    <div class="effect-building" v-if="!data.noAnimation">
      <img :src="require('../../../assets/images/buildings.svg').default" alt="SVG Animate">
      <div class="black-background"></div>
    </div>
    <div class="row bottom-elements align-items-center">
      <div class="col col-xs-12 col-6">
        <OrganismTitleAndDescription :vision-data="data.vision" />
      </div>
      <div class="col col-xs-12 col-6">
        <OrganismContentSlider :slider-content-data="data.slider">

        </OrganismContentSlider>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import OrganismTitleAndDescription from "../../../components/organisms/common/OrganismTitleAndDescription";
import OrganismContentSlider from "../../../components/organisms/common/OrganismContentSlider";
export default {
  name: "SectionVision",
  components: {OrganismContentSlider, OrganismTitleAndDescription, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          noAnimation: false,
          vision: [],
          slider: []
        }
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    subtitle: {
      required: false,
      type: String,
      default: ""
    },
  },
  computed: {
    titleString() {
      return this.title || "The";
    },
    subtitleString() {
      return this.subtitle || "Vision";
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-vision {
    padding: 6.25rem 0 3.125rem;
    background: $black;
    border-bottom: 1px solid $white;
    min-height: 56.25rem;
    overflow: hidden;
    position: relative;
    .navigation-target {
      position: absolute;
      top: -3.563rem;
      left: 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 0;
    }
    .top-title {
      padding: 0 5.625rem;
      position: relative;
      z-index: 7;
      @media only screen and (max-width: 767px) {
        padding: 0 20px;
        margin-bottom: 60px;
      }
    }
    .effect-building {
      position: absolute;
      top: -10rem;
      left: -3.5rem;
      width: 100%;
      z-index: 1;
      @media only screen and (max-width: 767px) {
        top: -186px;
        left: -102px;
        width: 1300px;
      }
      #building-svg {
        width: 100%;
      }
      .black-background {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 9;
        background: $black;
        width: 100%;
        animation: 7s ease 0s normal none infinite running building-effect;
      }
    }
    .bottom-elements {
      position: absolute;
      bottom: 4.688rem;
      z-index: 8;
      width: 100%;
      padding: 0 5.625rem 0 9.375rem;
      margin: 0 -1.875rem;
      @media only screen and (max-width: 767px) {
        position: relative;
        bottom: 0;
        padding: 0 20px;
        margin: 0;
      }
      .col {
        padding: 0 1.875rem;
        @media only screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }
  @keyframes building-effect {
    0% {width: 100%;}
    100% {width: 0;}
  }
</style>