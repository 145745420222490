var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "atom-scroll-up": true, active: _vm.isScrolled },
      on: { click: _vm.goBackToTopSection }
    },
    [_c("AtomIcon", { attrs: { icon: "angle-up", size: 30, color: "#000" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }