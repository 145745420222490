<template>
  <div class="organsim-title-and-description">
    <MoleculeTitleAndDescription>
      <AtomTitleAndDescription v-for="(vision, index) in visionData" :title="vision.title" :description="vision.description" :key="index" />
    </MoleculeTitleAndDescription>
  </div>
</template>

<script>
import MoleculeTitleAndDescription from "../../molecules/common/MoleculeTitleAndDescription";
import AtomTitleAndDescription from "../../atoms/common/AtomTitleAndDescription";
export default {
  name: "OrganismTitleAndDescription",
  components: {AtomTitleAndDescription, MoleculeTitleAndDescription},
  props: {
    visionData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .organsim-title-and-description {

  }
</style>