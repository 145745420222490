<template>
  <div :class="{section: true, 'section-creators': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle color="#fff" color2="#fff" :title="titleString" :subtitle="subtitleString" />
      <AtomVerticalLine/>
      <span class="info-title">Main Shareholders</span>
    </div>
    <OrganismCreator :creator-data="data" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../components/atoms/common/AtomVerticalLine";
import OrganismCreator from "../../../components/organisms/portfolio/OrganismCreator";
export default {
  name: "SectionCreators",
  components: {OrganismCreator, AtomVerticalLine, AtomTitle},
  props: {
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    subtitle: {
      required: false,
      type: String,
      default: ""
    },
  },
  computed: {
    titleString() {
      return this.title || "The";
    },
    subtitleString() {
      return this.subtitle || "Creators";
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-creators {
    padding: 6.25rem 5.625rem 0;
    background: $main-color;
    position: relative;
    .navigation-target {
      position: absolute;
      top: -3.563rem;
      left: 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px;
    }
    .top-title {
      position: relative;
      margin-bottom: 3.75rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
      }
      .atom-title {
        margin-bottom: 1.875rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 20px;
        }
      }
      .info-title {
        position: absolute;
        right: 0;
        top: 3.25rem;
        font-weight: $medium;
        font-size: 1.25rem;
        line-height: 1.5rem;
        text-align: right;
        color: $white;
        @media only screen and (max-width: 767px) {
          font-size: 12px;
          top: 34px;
          line-height: 18px;
        }
      }
    }
  }
</style>