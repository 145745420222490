var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col col-xs-12 col-4 atom-padding" }, [
    _c("div", { staticClass: "atom-creator" }, [
      _c("div", { staticClass: "shadow-element" }),
      _c(
        "div",
        { staticClass: "atom-gradient-background" },
        [
          !_vm.isInView
            ? _c("AtomLazyLoader", { attrs: { height: 425 } })
            : _vm._e(),
          _c(
            "picture",
            {
              directives: [
                {
                  name: "lazyload",
                  rawName: "v-lazyload",
                  value: { handle: _vm.handleLazyLoad, url: _vm.assetUrl },
                  expression: "{handle: handleLazyLoad, url: assetUrl}"
                }
              ]
            },
            [
              _c("source", { attrs: { srcset: _vm.assetUrl } }),
              _vm.assetUrl
                ? _c("img", { attrs: { src: _vm.assetUrl, alt: _vm.name } })
                : _vm._e()
            ]
          ),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _c(
            "a",
            {
              staticClass: "linkedin-icon",
              attrs: { target: "_blank", href: "" + _vm.urlLinkedin }
            },
            [
              _c("AtomIcon", {
                attrs: { icon: "linkedin", color: "#fff", size: 35 }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }