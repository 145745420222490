<template>
  <div class="atom-title-and-description">
    <h4>{{ title }}</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "AtomTitleAndDescription",
  props: {
    title: {
      required: true,
      type: String,
      default: ''
    },
    description: {
      required: true,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-title-and-description {
    flex: 0 0 50%;
    padding: 0 1.75rem;
    margin: 1.875rem 0;
    @media only screen and (max-width: 767px) {
      padding: 0;
      flex: 1 0 100%;
      margin: 15px 0;
    }
    h4 {
      font-weight: $bold;
      font-size: 1.938rem;
      line-height: 2.375rem;
      color: $second-color;
      margin-bottom: 1.25rem;
      @media only screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 15px;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $white;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
</style>